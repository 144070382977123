# chars = ["\\", '|', '/' ,'–']
# chars = [ '˥', '˦', '˧', '˨', '˩','˨','˧','˦']
# chars = ['|', 'O', '–']
# chars = ['◐', '◓', '◑', '◒']
# chars = ['◧', '◩', '◨', '◪']
# chars = ['◰', '◳', '◲', '◱']
# chars = ['◴', '◷', '◶', '◵']
# chars = ['◿', '◺', '◸', '◹']
# chars = ['◜', '◝','◞','◟']
# chars = ['⠁','⠂','⠄','⡀','⢀','⠠','⠐','⠈']
# chars = ["⣷", "⣯", "⣟", "⡿", "⢿", "⣻", "⣽", "⣾"]
# chars = ["⠃", "⠆", "⡄", "⣀", "⢠", "⠰", "⠘", "⠉"]
# chars = ["⠃", "⠆", "⡄", "⣀", "⢠", "⠰", "⠘", "⠉"]
# chars = ["⠃", "⠆", "⡄", "⣀", "⢠", "⠰", "⠘", "⠉",]
# chars = ["⣛", "⣭", "⣶", "⣿","⣿","⣶","⣭","⣛","⢿", "⡿"]
chars = [
        "⣷", "⣯", "⣟", "⡿", "⢿", "⣻", "⣽", "⣾",
         "⣷", "⣯", "⣟", "⡿", "⢿", "⣻", "⣽", "⣾",
         "⣷", "⣯", "⣽", "⣻", "⣟", "⡿", "⢿","⣻","⣟","⣯","⣽","⣾",
         "⣾", "⣽", "⣻", "⢿", "⡿", "⣟", "⣯", "⣷",
         "⣷", "⣯", "⣽", "⣻", "⣟", "⡿", "⢿","⣻","⣟","⣯","⣽","⣾",
         "⣾", "⣽", "⣻", "⢿", "⡿", "⣟", "⣯", "⣷"
        ]
# chars = [
#   "⣾","⣷", "⣯", "⣽", "⣻", "⣟", "⡿", "⢿","⣻","⣟","⣯","⣽","⣾",
#   "⣷","⣾", "⣽", "⣯", "⣟", "⣻", "⢿", "⡿","⢿", "⡿", "⣟", "⣻", "⣽", "⣯", "⣷"
# ]
# chars = [ "⣾","⣷","⣾", "⣯","⣽","⣯",  "⣻", "⣟","⣻",  "⡿", "⢿","⡿", "⣻","⣟","⣻", "⣯","⣽","⣯","⣾","⣷", "⣯", "⣽", "⣻", "⣟", "⡿", "⢿","⣻","⣟","⣯","⣽","⣾",
#   "⣷","⣾", "⣽", "⣯", "⣟", "⣻", "⢿", "⡿","⢿", "⡿", "⣟", "⣻", "⣽", "⣯", "⣷"]

# ---------------------------------------------- Text output

output = [
  "Copying SSH keys into build environment..."
  "Setting up directories and permissions..."
  "Cleaning up any previous builds..."
  "Extracting pkg cache..."
  "Updating pkg database..."
  "Copying codebase..."
  "Cloning into 'engine'..."
  "remote: Counting objects: 562, done."
  "Checking connectivity... done."
  "Branch refactor/v1 set up to track remote branch refactor/v1 from origin."
  "Switched to a new branch 'refactor/v1'"
  "Fetch 'vendor/bundle' from cache..."
  "Fetch '.bundle' from cache..."
  "Fetch 'node_modules' from cache..."
  "calculating dependencies... done."
  "nothing to upgrade."
  "7 packages to be installed (0B to download, 145M to install):"
  "libyaml-0.1.6nb1 libffi-3.2.1nb1 ruby-2.2.4nb1 ruby22-bundler-1.12.5 nodejs-4.2.6nb1 python27-2.7.11nb2 postgresql94-client-9.4.8nb1"
  "downloading packages..."
  "installing packages..."
  "installing libyaml-0.1.6nb1..."
  "installing libffi-3.2.1nb1..."
  "installing ruby-2.2.4nb1..."
  "installing ruby22-bundler-1.12.5..."
  "installing nodejs-4.2.6nb1..."
  "installing python27-2.7.11nb2..."
  "installing postgresql94-client-9.4.8nb1..."
  "pkg_install warnings: 7, errors: 0"
  "pkg_install error log can be found in /data/var/db/pkgin/pkg_install-err.log"
  "reading local summary..."
  "processing local summary..."
  "marking ruby-2.2.4nb1 as non auto-removable"
  "marking ruby22-bundler-1.12.5 as non auto-removable"
  "marking nodejs-4.2.6nb1 as non auto-removable"
  "marking python27-2.7.11nb2 as non auto-removable"
  "marking postgresql94-client-9.4.8nb1 as non auto-removable "
]

$pre        = $ 'pre.update'
$action     = $ 'pre.action'
$progress   = $ 'pre.progress'
spinIndex   = 0
outputIndex = 0


# ------------------------------- Methods

# Spinner
spin = ()=>
  $pre.text "  #{chars[spinIndex]} Compiling code:"
  if ++spinIndex == chars.length
    spinIndex = 0
  setTimeout spin, 80

# Status
updateCurrentAction = ()=>
  $action.text "    #{output[outputIndex]}"
  if ++outputIndex == output.length
    outputIndex = 0
  setTimeout updateCurrentAction, Math.random() * 2500
  
# Progress Bar
percentageComplete = 0
estimatedDuration  = 16000
blockSize          = 100 / (estimatedDuration/100)*1.5
moveProgressBar = ()=>
  # The ammount to move the progress bar
  if percentageComplete < 65
    percentageComplete += Math.random() * blockSize
  else if percentageComplete < 90
    percentageComplete += (100 - percentageComplete) * 0.03
  else
    percentageComplete += (100 - percentageComplete) * 0.01
  
  if percentageComplete > 99 
    percentageComplete = 99
  
  #  Convert the percentage into a text progressbar
  str = ''
  for i in [0..50]
    str += if i < percentageComplete/2 then "+" else " "
  
  $progress.text "\n    [#{str}] #{Math.floor(percentageComplete)}%"
  setTimeout moveProgressBar, Math.random() * 300
  
spin()
updateCurrentAction()
moveProgressBar()